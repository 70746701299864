import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const MarkdownPage = ({ name }) => {
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    fetch(`/${name}.md`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to load: ${response.statusText}`);
        }
        return response.text();
      })
      .then(text => {
        setContent(text);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error loading markdown:', error);
        setError(error.message);
        setLoading(false);
      });
  }, [name]);

  useEffect(() => {
    // Add anchor scrolling effect after content is loaded
    if (!loading && !error) {
      // Override the hash link behavior completely to handle it manually
      document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
          e.preventDefault();
          
          const href = this.getAttribute('href');
          const targetId = href.substring(1); // Remove the # character
          const targetElement = document.getElementById(targetId);
          
          if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
            // Update URL hash without triggering scroll (for bookmarking)
            window.history.pushState(null, null, href);
          }
        });
      });

      // Handle direct hash navigation (initial page load with hash)
      const handleInitialHash = () => {
        const hash = window.location.hash;
        if (hash) {
          const id = hash.substring(1);
          const element = document.getElementById(id);
          if (element) {
            setTimeout(() => {
              element.scrollIntoView({ behavior: 'smooth' });
            }, 100);
          }
        }
      };

      handleInitialHash();
    }
  }, [loading, error, content]);

  if (loading) {
    return <div className="max-w-4xl mx-auto px-4 py-8">Loading...</div>;
  }

  if (error) {
    return <div className="max-w-4xl mx-auto px-4 py-8 text-red-600">Error: {error}</div>;
  }

  // Custom renderers for headings to add IDs for anchor links that match the markdown links
  const components = {
    h1: ({ node, children, ...props }) => {
      // Extract text content for ID generation
      const text = children.toString();
      let id;
      
      // Special handling for numbered sections like "# 1. Introduction & Vision"
      const numberedHeadingMatch = text.match(/^(\d+)\.\s+(.+)$/);
      if (numberedHeadingMatch) {
        // Format: 1-introduction--vision (to match the links in the markdown)
        id = numberedHeadingMatch[1] + '-' + numberedHeadingMatch[2].toLowerCase()
          .replace(/\s+/g, '-')
          .replace(/&/g, '')
          .replace(/[^\w-]/g, '');
      } else {
        // Regular heading
        id = text.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]/g, '');
      }
      
      console.log("Generated ID for heading:", text, "->", id);
      return <h1 id={id} {...props}>{children}</h1>;
    },
    h2: ({ node, children, ...props }) => {
      const text = children.toString();
      const id = text.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]/g, '');
      return <h2 id={id} {...props}>{children}</h2>;
    },
    h3: ({ node, children, ...props }) => {
      const text = children.toString();
      const id = text.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]/g, '');
      return <h3 id={id} {...props}>{children}</h3>;
    }
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <ReactMarkdown 
        remarkPlugins={[remarkGfm]} 
        className="markdown-content prose prose-slate lg:prose-lg max-w-none"
        components={components}
      >
        {content}
      </ReactMarkdown>
    </div>
  );
};

export default MarkdownPage;