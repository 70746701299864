import React, { useState, useEffect } from 'react';
import { Cloud, ArrowRight, Brain, Lock, Layout, BookOpen, Twitter, MessageCircle, Sparkles, Podcast } from 'lucide-react';
// Import your logo files
import logoIcon from './assets/logoIcon.png';
import textLogo from './assets/Text-Logo.png';

const TinyCloudLanding = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({
        x: (e.clientX / window.innerWidth) * 2 - 1,
        y: (e.clientY / window.innerHeight) * 2 - 1
      });
    };
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    setEmail('');
  };

  return (
    <div className="min-h-screen relative overflow-hidden bg-[#4573B9]">
      {/* Background gradient overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-[#282F4B] via-[#4573B9] to-[#4573B9] opacity-90" />

      {/* Magical background elements */}
      <div className="absolute inset-0 overflow-hidden">
        {/* Animated gradient orbs - updated with new colors */}
        <div 
          className="absolute w-[800px] h-[800px] rounded-full blur-3xl opacity-30 animate-float"
          style={{
            background: 'linear-gradient(45deg, rgba(125, 176, 210, 0.6), rgba(69, 115, 185, 0.4))',
            transform: `translate(${mousePosition.x * 20}px, ${mousePosition.y * 20}px)`,
            top: '-20%',
            right: '-10%',
          }}
        />
        <div 
          className="absolute w-[600px] h-[600px] rounded-full blur-3xl opacity-20 animate-float-reverse"
          style={{
            background: 'linear-gradient(45deg, rgba(40, 47, 75, 0.4), rgba(125, 176, 210, 0.3))',
            transform: `translate(${mousePosition.x * -20}px, ${mousePosition.y * -20}px)`,
            bottom: '-10%',
            left: '-10%',
          }}
        />
        
        {/* Shimmering overlay */}
        <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ0cmFuc3BhcmVudCIvPjxwYXRoIGQ9Ik0wIDAgTDIwMCAyMDBNMjAwIDAgTDAgMjAwIiBzdHJva2U9InJnYmEoMjU1LDI1NSwyNTUsMC4wNSkiIHN0cm9rZS13aWR0aD0iMSIvPjwvc3ZnPg==')] opacity-20 animate-shimmer"></div>
        
        {/* Floating particles */}
        <div className="absolute inset-0">
          {[...Array(20)].map((_, i) => (
            <div
              key={i}
              className="absolute w-1 h-1 bg-white rounded-full animate-float-particle"
              style={{
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                animationDelay: `${Math.random() * 5}s`,
                opacity: 0.2
              }}
            />
          ))}
        </div>
      </div>

      <div className="relative flex flex-col items-center justify-center px-3 sm:px-4 min-h-screen py-12 sm:py-0">
        <div className="text-center w-full max-w-3xl mx-auto backdrop-blur-sm rounded-xl p-3 sm:p-8 bg-[#282F4B]/10">
          {/* Logo Section */}
          <div className="flex justify-center items-center gap-2 mb-6 sm:mb-8 group">
            <div className="relative transform hover:scale-110 transition-all duration-300">
              <img 
                src={logoIcon} 
                alt="TinyCloud Icon"
                className="h-8 w-8 sm:h-10 sm:w-10 object-contain"
              />
            </div>
            <img 
              src={textLogo}
              alt="TinyCloud"
              className="h-5 sm:h-6 object-contain"
            />
          </div>

          {/* Headline - Tagline */}
          <h1 className="text-2xl sm:text-4xl md:text-6xl font-bold mb-4 sm:mb-6 leading-tight tracking-tight px-2">
            <span className="text-[#FDF9D2]">
              The Sovereign Data Layer
            </span>
          </h1>

          {/* One-sentence claim */}
          <p className="font-space-grotesk text-xl sm:text-2xl md:text-3xl text-[#FDF9D2] mb-8 sm:mb-10 max-w-2xl mx-auto leading-snug sm:leading-relaxed px-2 sm:px-4 font-medium">
            Own your digital self.
          </p>
          
          {/* Longer explanation */}
          <p className="font-space-grotesk text-base sm:text-lg md:text-xl text-[#FDF9D2]/90 mb-8 sm:mb-12 max-w-2xl mx-auto leading-relaxed px-2 sm:px-4">
            Your personal data is the bedrock of your digital experience. TinyCloud is building the protocol that ensures this foundation stays under your control - from your conversations and memories to your preferences and digital interactions.
            <br /><br />
            Sovereign data isn't just about privacy - it's about enabling a future where AI works for you, with your context, on your terms.
          </p>

          {/* Product Buttons */}
          <div className="w-full max-w-md mx-auto mb-12 sm:mb-16 px-2">
            <div className="flex flex-col gap-4">
              <a 
                href="/muse" 
                className="w-full px-6 py-3 bg-[#7DB0D2] hover:bg-[#7DB0D2]/90 text-[#282F4B] font-bold rounded-lg flex items-center justify-center gap-3 transition-all hover:scale-105 text-lg sm:text-xl"
              >
                <Sparkles className="h-5 w-5" />
                Try Muse AI
                <ArrowRight className="h-5 w-5" />
              </a>
              <a 
                href="/autograph" 
                className="w-full px-6 py-3 bg-[#7DB0D2]/80 hover:bg-[#7DB0D2]/70 text-[#282F4B] font-bold rounded-lg flex items-center justify-center gap-3 transition-all hover:scale-105 text-lg sm:text-xl"
              >
                <BookOpen className="h-5 w-5" />
                Explore Autograph
                <ArrowRight className="h-5 w-5" />
              </a>
              <a 
                href="/protocol" 
                className="w-full px-6 py-3 bg-[#7DB0D2]/60 hover:bg-[#7DB0D2]/50 text-[#282F4B] font-bold rounded-lg flex items-center justify-center gap-3 transition-all hover:scale-105 text-lg sm:text-xl"
              >
                <Cloud className="h-5 w-5" />
                TinyCloud Protocol
                <ArrowRight className="h-5 w-5" />
              </a>
            </div>
          </div>

          {/* Email Signup */}
          {/* <div className="w-full max-w-md mx-auto mb-6 sm:mb-8 px-2">
            <iframe
              src="https://embeds.beehiiv.com/dac302e9-6935-4bf0-a350-9adc1d55f797?slim=true"
              data-test-id="beehiiv-embed"
              height={52}
              frameBorder={0}
              scrolling="no"
              style={{ 
                margin: 0, 
                borderRadius: '0.5rem', 
                backgroundColor: 'transparent', 
                width: '100%',
              }}
            />
          </div> */}

          {/* Social Links - updated with new colors */}
          <div className="flex flex-col sm:flex-row justify-center gap-3 sm:gap-6 mb-10 sm:mb-16 px-2 sm:px-4">
            <a 
              href="https://t.me/+pplkv1XbbU01MDVh" 
              className="px-3 py-1.5 bg-[#282F4B]/20 backdrop-blur-sm border-2 border-[#7DB0D2]/30 text-[#FDF9D2] rounded-lg hover:bg-[#282F4B]/30 font-medium flex items-center justify-center gap-2 transition-all hover:scale-105 text-sm w-full sm:w-auto"
              target="_blank"
              rel="noopener noreferrer"
            >
              <MessageCircle className="h-4 w-4" /> Join Our Telegram <ArrowRight className="h-3 w-3" />
            </a>
            <a 
              href="https://daccpod.buzzsprout.com/" 
              className="px-3 py-1.5 bg-[#282F4B]/20 backdrop-blur-sm border-2 border-[#7DB0D2]/30 text-[#FDF9D2] rounded-lg hover:bg-[#282F4B]/30 font-medium flex items-center justify-center gap-2 transition-all hover:scale-105 text-sm w-full sm:w-auto"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Podcast className="h-4 w-4" /> Listen to d/acc Podcast <ArrowRight className="h-3 w-3" />
            </a>
            <a 
              href="https://x.com/TinyCloudLabs" 
              className="px-3 py-1.5 bg-[#282F4B]/20 backdrop-blur-sm border-2 border-[#7DB0D2]/30 text-[#FDF9D2] rounded-lg hover:bg-[#282F4B]/30 font-medium flex items-center justify-center gap-2 transition-all hover:scale-105 text-sm w-full sm:w-auto"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Twitter className="h-4 w-4" /> Follow on X <ArrowRight className="h-3 w-3" />
            </a>
          </div>

          {/* Core Values Section */}
          <h2 className="text-xl sm:text-2xl font-bold text-[#FDF9D2] mb-6 mt-8">Core Values</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8 mb-10 sm:mb-16 px-2 sm:px-4">
            {[
              { icon: Lock, title: "Sovereignty", desc: "Built so that each user controls their data outright. Requests to access or compute on that data must be explicitly permissioned." },
              { icon: Brain, title: "Privacy", desc: "Data is stored, streamed, and computed upon in ways that minimize leakage with encryption strategies that ensure users do not need to trust an external party." },
              { icon: Layout, title: "Interoperability", desc: "Embracing artifact-based formats like Markdown, JSON Canvas, CSV, and SQLite so that data remains portable and future-proof." },
              { icon: Sparkles, title: "Open Innovation", desc: "We view AI's rapid growth as an opportunity to endow individuals with new capabilities—before these capabilities are seized exclusively by large institutions." }
            ].map(({ icon: Icon, title, desc }) => (
              <div key={title} className="flex flex-col items-center group p-4 sm:p-5 bg-[#282F4B]/10 rounded-lg backdrop-blur-sm border border-[#7DB0D2]/20 hover:border-[#7DB0D2]/40 hover:bg-[#282F4B]/20 transition-all duration-300">
                <div className="relative mb-3 sm:mb-4">
                  <Icon className="h-5 w-5 sm:h-6 sm:w-6 text-[#7DB0D2]" />
                  <div className="absolute inset-0 animate-ping opacity-50 group-hover:opacity-100">
                    <Icon className="h-5 w-5 sm:h-6 sm:w-6 text-[#7DB0D2]" />
                  </div>
                </div>
                <h3 className="font-medium text-[#FDF9D2] mb-2 text-base sm:text-lg">{title}</h3>
                <p className="text-sm text-[#FDF9D2]/80 text-center leading-relaxed">
                  {desc}
                </p>
              </div>
            ))}
          </div>

          {/* Bottom Message */}
          <p className="text-xs sm:text-sm text-[#FDF9D2]/60 px-2">
            Join the future of personal AI - where your digital world works for you
          </p>
          <div className="mt-4">
            <a 
              href="/privacy"
              className="text-xs sm:text-sm text-[#FDF9D2]/60 hover:text-[#FDF9D2]/80 underline"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TinyCloudLanding;