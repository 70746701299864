import React from 'react';
import { BookOpen, ArrowRight, Network, GitBranch, Database, Lock, Brain } from 'lucide-react';
import logoIcon from './assets/logoIcon.png';
import textLogo from './assets/Text-Logo.png';

const AutographLanding = () => {
  return (
    <div className="min-h-screen relative overflow-hidden bg-[#4573B9]">
      {/* Background gradient overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-[#282F4B] via-[#4573B9] to-[#4573B9] opacity-90" />

      <div className="relative flex flex-col items-center justify-center px-3 sm:px-4 min-h-screen py-12 sm:py-0">
        <div className="text-center w-full max-w-3xl mx-auto backdrop-blur-sm rounded-xl p-3 sm:p-8 bg-[#282F4B]/10">
          {/* Logo Section */}
          <a href="https://tinycloud.xyz">
            <div className="flex justify-center items-center gap-2 mb-6 sm:mb-8 group">
              <div className="relative transform hover:scale-110 transition-all duration-300">
                <img 
                  src={logoIcon} 
                  alt="TinyCloud Icon"
                  className="h-8 w-8 sm:h-10 sm:w-10 object-contain"
                />
              </div>
              <img 
                src={textLogo}
                alt="TinyCloud"
                className="h-5 sm:h-6 object-contain"
              />
            </div>
          </a>

          {/* Headline - Tagline */}
          <h1 className="text-2xl sm:text-4xl md:text-6xl font-bold mb-4 sm:mb-6 leading-tight tracking-tight px-2">
            <span className="text-[#FDF9D2]">
              Autograph
            </span>
          </h1>

          {/* One-sentence claim */}
          <p className="font-space-grotesk text-xl sm:text-2xl md:text-3xl text-[#FDF9D2] mb-8 sm:mb-10 max-w-2xl mx-auto leading-snug sm:leading-relaxed px-2 sm:px-4 font-medium">
            Your knowledge, structured and sovereign.
          </p>
          
          {/* Longer explanation */}
          <p className="font-space-grotesk text-base sm:text-lg md:text-xl text-[#FDF9D2]/90 mb-8 sm:mb-12 max-w-2xl mx-auto leading-relaxed px-2 sm:px-4">
            Autograph is the structured knowledge layer and API designed to store and retrieve user data—especially unstructured text like transcripts—while converting it into an interlinked knowledge graph. It can parse text into nodes (people, places, organizations, concepts) and link them, creating a comprehensive knowledge base that grows with you.
          </p>

          {/* Call to Action Button */}
          <div className="w-full max-w-md mx-auto mb-12 sm:mb-16 px-2">
            <a 
              href="/" 
              className="w-full px-6 py-3 bg-[#7DB0D2] hover:bg-[#7DB0D2]/90 text-[#282F4B] font-bold rounded-lg flex items-center justify-center gap-3 transition-all hover:scale-105 text-lg sm:text-xl"
            >
              <BookOpen className="h-5 w-5" />
              Learn More
              <ArrowRight className="h-5 w-5" />
            </a>
          </div>

          {/* Features Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-10 mb-16 sm:mb-20 px-4 sm:px-6">
            {[
              { 
                icon: Network, 
                title: "Knowledge Graph", 
                desc: "Automatically converts text into connected knowledge nodes, creating a rich semantic network of your data." 
              },
              { 
                icon: GitBranch, 
                title: "GitHub Sync", 
                desc: "Synchronize with Obsidian and other markdown-based knowledge management tools through GitHub." 
              },
              { 
                icon: Database, 
                title: "TinyCloud Sync", 
                desc: "Store your knowledge using TinyCloud for true data sovereignty and portability." 
              },
              { 
                icon: Brain, 
                title: "LLM Integration", 
                desc: "Can be plugged into AI models, enabling agents to store new facts or retrieve relevant context as they chat with you." 
              }
            ].map(({ icon: Icon, title, desc }) => (
              <div key={title} className="flex flex-col items-center group p-6 sm:p-8 bg-[#282F4B]/10 rounded-lg backdrop-blur-sm border border-[#7DB0D2]/20 hover:border-[#7DB0D2]/40 hover:bg-[#282F4B]/20 transition-all duration-300">
                <div className="relative mb-4 sm:mb-5">
                  <Icon className="h-6 w-6 sm:h-8 sm:w-8 text-[#7DB0D2]" />
                  <div className="absolute inset-0 animate-ping opacity-50 group-hover:opacity-100">
                    <Icon className="h-6 w-6 sm:h-8 sm:w-8 text-[#7DB0D2]" />
                  </div>
                </div>
                <h3 className="font-medium text-[#FDF9D2] mb-3 text-lg sm:text-xl">{title}</h3>
                <p className="text-sm sm:text-base text-[#FDF9D2]/80 text-center leading-relaxed">
                  {desc}
                </p>
              </div>
            ))}
          </div>

          {/* How It Works Section */}
          <div className="mb-16 sm:mb-20 px-4">
            <h2 className="text-xl sm:text-2xl font-bold text-[#FDF9D2] mb-6">How It Works</h2>
            <div className="flex flex-col space-y-4">
              <div className="bg-[#282F4B]/20 rounded-lg p-4 sm:p-6 text-left">
                <h3 className="text-lg font-semibold text-[#7DB0D2] mb-2">(text, bucket) ⇒ updates the bucket</h3>
                <p className="text-[#FDF9D2]/80 text-sm sm:text-base">
                  Pass large text blocks like conversation transcripts, and Autograph will parse them into structured notes and graph nodes.
                </p>
              </div>
              <div className="bg-[#282F4B]/20 rounded-lg p-4 sm:p-6 text-left">
                <h3 className="text-lg font-semibold text-[#7DB0D2] mb-2">(query, bucket) ⇒ retrieves knowledge</h3>
                <p className="text-[#FDF9D2]/80 text-sm sm:text-base">
                  Search or retrieve notes easily; AI agents can be permissioned to read or write data for better reasoning.
                </p>
              </div>
            </div>
          </div>

          {/* Bottom Links */}
          <div className="flex justify-center gap-4 text-sm">
            <a 
              href="/" 
              className="text-[#FDF9D2]/60 hover:text-[#FDF9D2]/80 underline"
            >
              Back to TinyCloud
            </a>
            <a 
              href="/privacy" 
              className="text-[#FDF9D2]/60 hover:text-[#FDF9D2]/80 underline"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutographLanding;