import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TinyCloudLanding from './TinyCloudLanding';
import MuseLanding from './MuseLanding';
import AutographLanding from './AutographLanding';
import ProtocolLanding from './ProtocolLanding';
import MarkdownPage from './MarkdownPage';
import NotFound from './NotFound';
import { Redirect, redirects } from './Redirect';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<TinyCloudLanding />} />
        <Route path="/muse" element={<MuseLanding />} />
        <Route path="/autograph" element={<AutographLanding />} />
        <Route path="/protocol" element={<ProtocolLanding />} />
        <Route path="/privacy" element={<MarkdownPage name="Privacy" />} />
        <Route path="/delete-user" element={<MarkdownPage name="DeleteUser" />} />
        <Route path="/litepaper" element={<MarkdownPage name="TinyCloudLitepaper" />} />
        {Object.entries(redirects).map(([path, redirect]) => (
          <Route key={path} path={path} element={<Redirect to={redirect} />} />
        ))}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;