import { Navigate } from 'react-router-dom';

export const redirects = {
  '/android': 'https://play.google.com/store/apps/details?id=com.tinycloud.app.muse',
  '/ios': 'https://testflight.apple.com/join/UrtvpNMn',
  '/meet-team': 'https://cal.com/team/tinycloud/intro',
  '/meet-sam': 'https://cal.com/samgbafa/intro',
  '/meet-sam-private': 'https://cal.com/samgbafa/meeting',
  '/sozu-hack': 'https://youtube.com/shorts/k23K9TOdxdA?feature=share',

  // Add more redirects as needed
};

export const Redirect = () => {
  // Get the current path
  const path = window.location.pathname;

  // Check if we have a redirect for this path
  const destination = redirects[path];

  if (destination) {
    // For external URLs, we need to use window.location
    window.location.href = destination;
    return null;
  }

  // If no redirect is found, navigate to home or show a 404
  return <Navigate to="/404" replace />;
};

export default Redirect;