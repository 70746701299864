import React from 'react';
import { Cloud, Database, Lock, Server, Key, Code, Cpu, ArrowRight, BookOpen, Pointer, Github } from 'lucide-react';
import logoIcon from './assets/logoIcon.png';
import textLogo from './assets/Text-Logo.png';

const ProtocolLanding = () => {
  return (
    <div className="min-h-screen relative overflow-hidden bg-[#4573B9]">
      {/* Background gradient overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-[#282F4B] via-[#4573B9] to-[#4573B9] opacity-90" />

      {/* Magical background elements */}
      <div className="absolute inset-0 overflow-hidden">
        {/* Animated gradient orbs */}
        <div 
          className="absolute w-[800px] h-[800px] rounded-full blur-3xl opacity-30 animate-float"
          style={{
            background: 'linear-gradient(45deg, rgba(125, 176, 210, 0.6), rgba(69, 115, 185, 0.4))',
            top: '-20%',
            right: '-10%',
          }}
        />
        <div 
          className="absolute w-[600px] h-[600px] rounded-full blur-3xl opacity-20 animate-float-reverse"
          style={{
            background: 'linear-gradient(45deg, rgba(40, 47, 75, 0.4), rgba(125, 176, 210, 0.3))',
            bottom: '-10%',
            left: '-10%',
          }}
        />
        
        {/* Shimmering overlay */}
        <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ0cmFuc3BhcmVudCIvPjxwYXRoIGQ9Ik0wIDAgTDIwMCAyMDBNMjAwIDAgTDAgMjAwIiBzdHJva2U9InJnYmEoMjU1LDI1NSwyNTUsMC4wNSkiIHN0cm9rZS13aWR0aD0iMSIvPjwvc3ZnPg==')] opacity-20 animate-shimmer"></div>
      </div>

      <div className="relative flex flex-col items-center justify-center px-3 sm:px-4 min-h-screen py-12 sm:py-0">
        <div className="text-center w-full max-w-3xl mx-auto backdrop-blur-sm rounded-xl p-3 sm:p-8 bg-[#282F4B]/10">
          {/* Logo Section */}
          <a href="https://tinycloud.xyz">
            <div className="flex justify-center items-center gap-2 mb-6 sm:mb-8 group">
              <div className="relative transform hover:scale-110 transition-all duration-300">
                <img 
                  src={logoIcon} 
                  alt="TinyCloud Icon"
                  className="h-8 w-8 sm:h-10 sm:w-10 object-contain"
                />
              </div>
              <img 
                src={textLogo}
                alt="TinyCloud"
                className="h-5 sm:h-6 object-contain"
                />
            </div>
          </a>

          {/* Headline - Tagline */}
          <h1 className="text-2xl sm:text-4xl md:text-6xl font-bold mb-4 sm:mb-6 leading-tight tracking-tight px-2">
            <span className="text-[#FDF9D2]">
              TinyCloud Protocol
            </span>
          </h1>

          {/* One-sentence claim */}
          <p className="font-space-grotesk text-xl sm:text-2xl md:text-3xl text-[#FDF9D2] mb-8 sm:mb-10 max-w-2xl mx-auto leading-snug sm:leading-relaxed px-2 sm:px-4 font-medium">
            The decentralized foundation for sovereign data.
          </p>
          
          {/* Longer explanation */}
          <p className="font-space-grotesk text-base sm:text-lg md:text-xl text-[#FDF9D2]/90 mb-8 sm:mb-12 max-w-2xl mx-auto leading-relaxed px-2 sm:px-4">
            The TinyCloud Protocol provides a decentralized or user-controlled "cloud" that serves as the back end for multiple apps. Instead of data silos, each user has a single, cohesive store of data (location, transcripts, knowledge graphs) that authorized apps can read or process, with privacy and security built-in.
          </p>

          {/* Call to Action Button */}
          <div className="w-full max-w-md mx-auto mb-12 sm:mb-16 px-2">
            <a 
              href="https://demo.tinycloud.xyz"
              className="w-full px-6 py-3 bg-[#7DB0D2] hover:bg-[#7DB0D2]/90 text-[#282F4B] font-bold rounded-lg flex items-center justify-center gap-3 transition-all hover:scale-105 text-lg sm:text-xl"
            >
              <Pointer className="h-5 w-5" />
              TinyCloud Example App
              <ArrowRight className="h-5 w-5" />
            </a>
            <br/>
            <a 
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.tinycloud.xyz" 
              className="w-full px-6 py-3 bg-[#7DB0D2] hover:bg-[#7DB0D2]/90 text-[#282F4B] font-bold rounded-lg flex items-center justify-center gap-3 transition-all hover:scale-105 text-lg sm:text-xl"
            >
              <Code className="h-5 w-5" />
              TinyCloud Web SDK
              <ArrowRight className="h-5 w-5" />
            </a>
            <br/>

            <a 
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/tinycloudlabs/tinycloud-node" 
              className="w-full px-6 py-3 bg-[#7DB0D2] hover:bg-[#7DB0D2]/90 text-[#282F4B] font-bold rounded-lg flex items-center justify-center gap-3 transition-all hover:scale-105 text-lg sm:text-xl"
            >
              <Github className="h-5 w-5" />
              TinyCloud Protocol GitHub
              <ArrowRight className="h-5 w-5" />
            </a>
            <br/>
            <a 
              href="/litepaper" 
              className="w-full px-6 py-3 bg-[#7DB0D2] hover:bg-[#7DB0D2]/90 text-[#282F4B] font-bold rounded-lg flex items-center justify-center gap-3 transition-all hover:scale-105 text-lg sm:text-xl"
            >
              <BookOpen className="h-5 w-5" />
              Litepaper
              <ArrowRight className="h-5 w-5" />
            </a>
          </div>

          {/* Key Components Section */}
          <h2 className="text-xl sm:text-2xl font-bold text-[#FDF9D2] mb-6">Key Components</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-16 px-4">
            {[
              { 
                icon: Database, 
                title: "Key-Value Store & Buckets", 
                desc: "Simple, flexible storage similar to an S3-like interface for all your data needs." 
              },
              { 
                icon: Cpu, 
                title: "Compute Layer", 
                desc: "Run functions or queries on your data with privacy-preserving computation." 
              },
              { 
                icon: Key, 
                title: "Encryption & Capabilities", 
                desc: "Fine-grained permission tokens and data-logic for secure access control." 
              },
              { 
                icon: Server, 
                title: "Decentralized Architecture", 
                desc: "User-controlled infrastructure that resists centralized control." 
              }
            ].map(({ icon: Icon, title, desc }) => (
              <div key={title} className="flex flex-col items-center group p-6 bg-[#282F4B]/10 rounded-lg backdrop-blur-sm border border-[#7DB0D2]/20 hover:border-[#7DB0D2]/40 hover:bg-[#282F4B]/20 transition-all duration-300">
                <div className="relative mb-4">
                  <Icon className="h-6 w-6 sm:h-8 sm:w-8 text-[#7DB0D2]" />
                  <div className="absolute inset-0 animate-ping opacity-50 group-hover:opacity-100">
                    <Icon className="h-6 w-6 sm:h-8 sm:w-8 text-[#7DB0D2]" />
                  </div>
                </div>
                <h3 className="font-medium text-[#FDF9D2] mb-3 text-lg">{title}</h3>
                <p className="text-sm text-[#FDF9D2]/80 text-center leading-relaxed">
                  {desc}
                </p>
              </div>
            ))}
          </div>

          {/* Use Cases Section */}
          <div className="mb-16 px-4">
            <h2 className="text-xl sm:text-2xl font-bold text-[#FDF9D2] mb-6">First Use Cases</h2>
            <div className="bg-[#282F4B]/20 rounded-lg p-6 text-left mb-4">
              <h3 className="text-lg font-semibold text-[#7DB0D2] mb-2">Location Streamer</h3>
              <p className="text-[#FDF9D2]/80 text-sm sm:text-base">
                An app that continuously streams a user's location into their TinyCloud node, giving you control over your location data.
              </p>
            </div>
            <div className="bg-[#282F4B]/20 rounded-lg p-6 text-left">
              <h3 className="text-lg font-semibold text-[#7DB0D2] mb-2">'Find My Friends' Clone</h3>
              <p className="text-[#FDF9D2]/80 text-sm sm:text-base">
                A privacy-preserving way to see if your friend is nearby or in a particular city—without ceding real-time location data to a third party's servers.
              </p>
            </div>
          </div>

          {/* Encryption Section */}
          <div className="mb-16 px-4">
            <h2 className="text-xl sm:text-2xl font-bold text-[#FDF9D2] mb-6">Encryption and Permissioning</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="bg-[#282F4B]/20 rounded-lg p-6">
                <h3 className="text-lg font-semibold text-[#7DB0D2] mb-2">Key Management</h3>
                <div className="text-[#FDF9D2]/80 text-sm sm:text-base text-left">
                  <ul className="list-disc list-inside space-y-2">
                    <li>Each artifact or data bucket is encrypted under keys that live with the user.</li>
                    <li>To share or compute on data, the user issues ephemeral permissions that can be revoked or narrowly scoped.</li>
                  </ul>
                </div>
              </div>
              <div className="bg-[#282F4B]/20 rounded-lg p-6">
                <h3 className="text-lg font-semibold text-[#7DB0D2] mb-2">Path Forward</h3>
                <div className="text-[#FDF9D2]/80 text-sm sm:text-base text-left">
                  <ul className="list-disc list-inside space-y-2">
                    <li>Adopting standardized approaches to capabilities-based security.</li>
                    <li>Ensuring that "post-quantum" or advanced cryptographic methods can be integrated over time.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* Bottom Links */}
          <div className="flex justify-center gap-4 text-sm">
            <a 
              href="/" 
              className="text-[#FDF9D2]/60 hover:text-[#FDF9D2]/80 underline"
            >
              Back to TinyCloud
            </a>
            <a 
              href="/privacy" 
              className="text-[#FDF9D2]/60 hover:text-[#FDF9D2]/80 underline"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProtocolLanding;